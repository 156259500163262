import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GlobalStyle from "./globalStyles";
import Home from "./pages/HomePage";
import "antd/dist/antd.css";
import { FaAngleUp } from "react-icons/fa";
import { BackTop } from "antd";
import spinner from "./spinner.gif";
import { Spin, Img } from "./SpinnerElements";
import ScrollToTop from './components/ScrollToTop';
import Delete from "./pages/Delete";

const style = {
  height: 40,
  width: 40,
  lineHeight: "45px",
  borderRadius: 4,
  backgroundColor: "#ffffff",
  color: "#1A71FF",
  textAlign: "center",
  fontSize: 20,
  border: "0.5px solid #1A71FF",
};

const Spinner = () => (
 <Spin>
     <Img src={spinner} alt="Loading..." />
 </Spin>
);

const About = lazy(() => import("./pages/About"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Pricing = lazy(() => import("./pages/Pricing"));
const TermsofService = lazy(() => import("./pages/TermsofService"));
const SupportCenter = lazy(() => import("./pages/SupportCenter"));
const Careers = lazy(() => import("./pages/Careers"));
const Blogs = lazy(() => import("./pages/Blog"));
const Security = lazy(() => import("./pages/Security"));
const Solutions = lazy(() => import("./pages/Solutions/"));
const PaymentStand = lazy(() => import("./pages/PaymentStand"));
const Dashboard = lazy(() => import("./pages/Dashboard/"));
const Faq = lazy(() => import("./pages/Faq"));
const Blog1 = lazy(() => import("./pages/BlogPosts/Blog1"))
const ScammedBlog = lazy(() => import("./pages/ScammedBlogPost/ScammedBlog"))

function App() {

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
       global.window && (global.window.location.href = 'https://play.google.com/store/apps/details?id=com.deempay.app');
       return null;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      global.window && (global.window.location.href = 'https://apps.apple.com/ng/app/deempay/id1660927213');
      return null;
    }

    return "unknown";
}

  return (
    <>
      <Router>
        <GlobalStyle />
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/delete" exact component={Delete} />
          <Route
              path="/dl"
              component={getMobileOperatingSystem}
          />
          {/* <Route
              path="/dl"
              component={() => {
              global.window && (global.window.location.href = 'https://play.google.com/store/apps/details?id=com.deempay.app');
              return null;
              }}
          /> */}
          <Suspense fallback={<Spinner />}>
            <Route path="/deempay-app" exact component={Solutions} />
            <Route path="/deempay-dashboard" exact component={Dashboard} />
            <Route path="/careers" exact component={Careers} />
            <Route path="/security" exact component={Security} />
            <Route path="/pricing" exact component={Pricing} />
            <Route path="/faq" exact component={Faq} />
            <Route path="/supportcenter" exact component={SupportCenter} />
            <Route path="/payment-stand" exact component={PaymentStand} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/termsofservice" exact component={TermsofService} />
            <Route path="/about" exact component={About} />
            <Route path="/blog" exact component={Blogs} />
            <Route path="/blog/creating-a-qr-code-for-your-business"  component={Blog1} />
            <Route path="/blog/how-i-was-scammed-at-my-shop"  component={ScammedBlog} />
          </Suspense>
        </Switch>
      </Router>
      <BackTop>
        <div style={style}>
          <div className="">{<FaAngleUp />}</div>
        </div>
      </BackTop>
    </>
  );
}

export default App;
